import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { rgba, darken } from 'polished'
import Image from 'src/components/Image'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import mediumZoom from 'medium-zoom'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Img from 'gatsby-image'

import { colors, mediaQueries as mq, animations, util, typography } from 'src/styles'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

import ArrowLeft from 'src/assets/images/arrow_left.svg'
import ArrowRight from 'src/assets/images/arrow_right.svg'

const thumbnailGutter = 10

// const StyledSlider = styled(Slider)`
// * {transition: height ${ animations.mediumSpeed } ease-in-out;}
// 	.slick-slide > div {
// 		display: flex;
// 		justify-content: center;
// 	}
// `

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
 

 ${ ({ slideshowType }) => `
		${ slideshowType  === 'default' ? `
			.slick-slide {

				.gatsby-image-wrapper {
					img {
						opacity: 0.5 !important;
					}
				}
				&.slick-active {
					.hidden-slide-overlay {
						opacity: 0;
					}
					.gatsby-image-wrapper {
						img {
							opacity: 1 !important;
						}
					}
				}
			}
		` : `
		` }
	` }

`

const RelativeDiv = styled.div`
	position: relative;
`

const SlideshowWrapper = styled.div`

	

	max-width: 100%;
	overflow: hidden;

`

const Slide = styled.div`
	${ ({ slideshowType }) => `
		${ slideshowType  === 'default' ? `
			position: relative;
		  img {
		  	padding: 0 10px;
		    max-width: 100%;
		    height: auto;
		  }
		  cursor: pointer;
		` : `
		` }
	` }

	
`

const SlideImageWrapper = styled.div`
	position: relative;

`

const SlideImage = styled(Image)`

`

const ImageCreditGrid = styled(Grid)`
	position: absolute;
	bottom: 0;
	left: 0;
	.image-credit {
		color: ${colors.white}
	}

`

const ImageCreditWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: ${ 100 / 14 }vw;
	z-index: 3;
	.image-credit {
		color: ${colors.white};
	}
	${ mq.mediumAndBelow } {
    bottom: 20px;
  }
`

const ImageCaptionWrapper = styled.div`
	padding: 0 10px;
`

const NextPrevButton = styled(Button)`
	cursor: pointer;
	position: absolute;
	top: calc( 50% - 80px );
	transform: translateY(-50%);
	margin: 0;
	z-index: 3;
	min-height: 32px;
	min-width: 32px;
	height: 34px;
	background: transparent !important;
	border: transparent !important;
	${ ({ position, dots }) => position === 'left' ? `
		left: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	` : `
		right: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	`}
	${ mq.smallAndBelow } {
		display: none;
	}

	svg {
		width: 18px;
		height: auto;

	}

	svg * {
		fill: none;
		transition: stroke 0.3s ease;
	}

	&:hover {
		cursor: pointer;
	}


`

const HiddenSlideOverlay = styled.div`
	background: ${colors.textColor};
	opacity: 1;
	position: absolute;
	top: 0;
	left: 10px;
	right: 10px;
	bottom: 0;
	height: 100%;
	z-index: -1;

`

const OverlaySection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  // background: linear-gradient(180deg, rgba(0, 66, 104, 0) 50%, #004268 85%);
  width: 100%;
`

const ProposalsSlideOverlay = styled.div`
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 66, 104, 0) 50%, #004268 85%);

`

const StyledHeader = styled.h1`
	color: ${colors.white};
`

const StyledRichText = styled.div`
	margin-top: 1em;
	margin-bottom: 1.5em;
	padding: 0 10px;
	p {
		line-height: 1.6em;
	}
`

class Slideshow extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	zoomImages: null,
    	windowWidth: null
    }
  }

	goToNextSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickNext()
		}
	}

	goToPrevSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickPrev()
		}
	}

	componentDidMount () {
		const images = typeof document !== `undefined` ? document.querySelectorAll("div.slideshow-image > picture > img") : null
		const windowWidth = typeof window !== `undefined` ? window.innerWidth : null
		this.setState({
			zoomImages: images,
			windowWidth: windowWidth
		});
  }

  shuffleArray = (array) => {
  	let i = array.length - 1;
	  for (; i > 0; i--) {
	    const j = Math.floor(Math.random() * (i + 1));
	    const temp = array[i];
	    array[i] = array[j];
	    array[j] = temp;
	  }
	  return array;
  }

	render () {
		// console.log('Render Slideshow Component', this.props)

		const {
		className,
		id,
		index,
		isFirstSection,
		isLastSection,
		nextTheme,
		pageType,
		prevTheme,
		prevIcon,
		nextIcon,
		pauseOnHover = true,
		autoplaySpeed = 3000,
		speed = 750,
		fade = false,
		autoplay = false,
		slideshowType
		} = this.props

		let { slides } = this.props

		if (!slides && !children) {
			return false
		}

		if (!slides) {
			let slides = children
		}

		const settings = {
			default: {
				dots: false,
				arrows: false,
				infinite: true,
				speed: speed,
				fade: false,
				autoplay: false,
				autoplaySpeed: autoplaySpeed,
				pauseOnHover: false,
				centerMode: true,
				adaptiveHeight: false,
				slidesToShow: 1,
				variableWidth: true,
				swipeToSlide: false
			},
			proposalsATF: {
				dots: false,
				arrows: false,
				infinite: true,
				speed: 750,
				fade: true,
				autoplay: true,
				autoplaySpeed: 4000,
				pauseOnHover: false,
				slidesToShow: 1,
			}
		}

		const setSlideshowSettings = settings[slideshowType]
		// const images = typeof document !== `undefined` ? document.querySelectorAll("div.slideshow-image > picture > img") : null
		// // var images = document.querySelectorAll("div.slideshow-image > picture > img");
		if ( this.state.zoomImages ) {
			mediumZoom(this.state.zoomImages, {
			  background: colors.textColor,
			  // background: 'bisque',
			  scrollOffset: this.state.windowWidth > 767 ? 400 : 0
			})
		}

		// console.log("THIS SLIDES", this.state);

		if ( slideshowType === 'proposalsATF') {
			slides = this.shuffleArray(slides);
		}
		return (
			<RelativeDiv onClick={void(0)}>
			<Wrapper className={className} slideshowType={slideshowType}>
		  	<Slider 
		  		{...setSlideshowSettings}
		  		ref={slider => (this.slideshow = slider)}
		  	>
		  		{slides.map((slide, index) => {
		  			let imge = slide.image.fluid
		  			let aspct = slide.image.fluid.aspectRatio
		  			if ( slideshowType === "default" ) {

		  				if ( this.state.windowWidth < 767 && slide.small ) {
	  						imge = slide.small.fluid
	  						aspct = slide.small.fluid.aspectRatio
		  				}

			  			return (
			  				<Fragment key={index}>
				  				<Slide style={{ width: aspct * 45 + 'vw' }} aspectRatio={aspct * 45} slideshowType={slideshowType}>
					  				<Img 
					  					fluid={imge}
					  					className='slideshow-image'
					  					/>
					  				<HiddenSlideOverlay className="hidden-slide-overlay"/>
					  			</Slide>

					  			{slide.imageCaptionStyled ? (
										<StyledRichText type='caption' style={{ width: aspct * 45 + 'vw' }}>
							    		<ContentfulRichText richText={slide.imageCaptionStyled.json}/>
						    		</StyledRichText>
										
									) : slide.imageCaption ? (
										<ImageCaptionWrapper style={{ width: aspct * 45 + 'vw' }}>
											<p className="image-caption">{slide.imageCaption.imageCaption}</p>
										</ImageCaptionWrapper>
									) : false }

									{slideshowType === 'proposalsATF' ? (
										<ProposalsSlideOverlay/>
									) : false }

									{slideshowType === 'proposalsATF' && slide.imageCredit ? (
										<ImageCreditWrapper>
											<p className="image-credit">{slide.imageCredit}</p>
										</ImageCreditWrapper>
									) : false}
								</Fragment>
			  			)
		  			} else {
		  				return (
		  					<Fragment key={index}>
				  				<Slide >
					  				<Image 
					  					image={slide.image}
					  					small={slide.small}
											loading={index === 0 ? "lazy" : "eager"}
				  					/>
					  			</Slide>

					  			{slide.imageCaptionStyled ? (
										<StyledRichText type='caption' style={{ width: slide.image.fluid.aspectRatio * 45 + 'vw' }}>
							    		<ContentfulRichText richText={slide.imageCaptionStyled.json}/>
						    		</StyledRichText>
										
									) : slide.imageCaption ? (
										<ImageCaptionWrapper style={{ width: slide.image.fluid.aspectRatio * 45 + 'vw' }}>
											<p className="image-caption">{slide.imageCaption.imageCaption}</p>
										</ImageCaptionWrapper>
									) : false }

									{slideshowType === 'proposalsATF' ? (
										<ProposalsSlideOverlay/>
									) : false }

									{slideshowType === 'proposalsATF' && slide.imageCredit ? (
										<ImageCreditWrapper>
											<p className="image-credit">{slide.imageCredit}</p>
										</ImageCreditWrapper>
									) : false}
								</Fragment>
	  					)
		  			}
		  		})}
		    </Slider>
		  </Wrapper>

		  {slides.length > 1 && slideshowType === 'default' && (
				<Fragment>
					<NextPrevButton shape="square" className="prev-button" size="small" setTheme="textColor" onClick={this.goToPrevSlide} position="left">{prevIcon ? prevIcon : <ArrowLeft/>}</NextPrevButton>
					<NextPrevButton shape="square" className="next-button" size="small" setTheme="textColor" onClick={this.goToNextSlide} position="right">{nextIcon ? nextIcon : <ArrowRight/>}</NextPrevButton>
				</Fragment>
			)}
			{slideshowType === 'proposalsATF' ? (
				<OverlaySection>
          <Grid 
          	small='1 [12] 1'
          	medium='1 [12] 1'
          	large='1 [12] 1'
        	>
            <StyledHeader>Proposals</StyledHeader>
          </Grid>
        </OverlaySection>

			) : false }

		  </RelativeDiv>
		)
	}
}

export default Slideshow
